<template>
  <router-view />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import projects from '@/assets/data/projects.json';

export default {
  data () {
    return {
      setVariable: true,
      projects
    };
  },
  computed: {
    ...mapState(['showProjectDetails', 'openingProject'])
  },
  created () {
    if (this.$route.name != 'home') {
      this.$router.push({
        name: 'home'
      });
    }
  },
  methods: {
    ...mapMutations({
      setOpeningProject: 'SET_OPENING_PROJECT',
      resetProjectDetails: 'RESET_PROJECT_DETAILS'
    }),
    handleProjectDetailsToProjects () {
      if (window.innerWidth >= 640) {
        this.resetProjectDetails();
      }
      const title = document.querySelector('.project-list__title-text');
      title.classList.add('hide');
      setTimeout(() => {
        title.innerText = 'Projects';
        if (window.innerWidth < 640) {
          title.classList.remove('hide');
        }
        setTimeout(() => {
          this.setOpeningProject(false);
        }, 800);
      }, 700);

      const projectListExpand = document.querySelector('.project-list__expand-mobile');
      projectListExpand.classList.remove('show');

      const selectedChip = document.querySelector(`.project:nth-child(${this.showProjectDetails + 1})`);

      if (selectedChip) {
        selectedChip.style.transform = 'translateY(0px)';
        selectedChip.classList.remove('selected');
      }
      setTimeout(() => {
        const companyChips = document.querySelectorAll(`.project:not(:nth-child(${this.showProjectDetails + 1})) .project-item`);
        companyChips.forEach(item => {
          item.classList.add('show');
        });
      }, 500);

      const scrollableContainer = document.querySelector('.project-list.show');
      scrollableContainer.style.overflow = 'auto';
    },
    handleProjectsToProjectDetails () {
      if (window.innerWidth >= 640) return;

      const companyChips = document.querySelectorAll(`.project:not(:nth-child(${this.showProjectDetails + 1})) .project-item`);

      const scrollableContainer = document.querySelector('.project-list.show');
      scrollableContainer.style.overflow = 'hidden';

      companyChips.forEach((item, index) => {
        item.classList.remove('show');
      });

      setTimeout(() => {
        const selectedChip = document.querySelector(`.project:nth-child(${this.showProjectDetails + 1})`);
        const selectedChipTranslation = selectedChip.offsetTop;
        const scrolledDistance = scrollableContainer.scrollTop;
        selectedChip.style.transform = `translateY(${(scrolledDistance - selectedChipTranslation + 88)}px)`;
        if (this.showProjectDetails == 0) {
          selectedChip.classList.add('selected');
        } else {
          setTimeout(() => {
            selectedChip.classList.add('selected');
          }, 1000);
        }
      }, 1000);
    },
    handleCircuitsToProjects () {
      const projectList = document.querySelector('.project-list');
      projectList.classList.remove('show');

      const companyChips = document.querySelectorAll('.project-item');
      companyChips.forEach(item => {
        item.classList.remove('show');
      });
    },
    async waitForEl (query) {
      const el = document.querySelector(query);
      if (el) return el;

      await new Promise(resolve => setTimeout(resolve, 300));

      return this.waitForEl(query);
    },
    async changeTitle (index) {
      if (index < 0) return;
      const title = await this.waitForEl('.project-list__title-text');
      title.classList.add('hide');
      setTimeout(() => {
        title.innerText = projects[index].companyName || 'Projects';
        title.classList.remove('hide');
      }, 700);
    }
  },
  watch: {
    showProjectDetails (val) {
      this.changeTitle(val);
    },
    '$route' (val, oldVal) {
      if (
        val.name == 'projects' &&
        oldVal.name == 'project-details'
      ) {
        this.handleProjectDetailsToProjects();
      } else if (
        val.name == 'project-details' &&
        oldVal.name == 'projects'
      ) {
        this.handleProjectsToProjectDetails();
      } else if (
        val.name == 'circuits' &&
        oldVal.name == 'projects'
      ) {
        this.handleCircuitsToProjects();
      }
    }
  }
};

</script>
<style lang="scss">
@import 'fonts/index.css';

#app {
  font-family: Quicksand, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  width: 100%;
  height: 100%;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
}

* {
  padding: 0;
  margin: 0;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

</style>
