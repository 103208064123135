import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import helpers from './helpers/helper';
import mitt from 'mitt';
const emitter = mitt();

const app = createApp(App);

app.use(store).use(router);

app.config.globalProperties.$helpers = helpers;
app.config.globalProperties.emitter = emitter;

app.mount('#app');
