export default {
  addVonovoLogoResize () {
    window.isMobile = window.innerWidth < 640;
    window.addEventListener('resize', this.translateVonovoLogo, true);
  },
  removeVonovoLogoResize () {
    window.removeEventListener('resize', this.translateVonovoLogo, true);
  },
  addVonovoLogoAboutMeResize () {
    window.isMobile = window.innerWidth < 640;
    window.addEventListener('resize', this.translateVonovoLogoAboutMe, true);
  },
  removeVonovoLogoAboutMeResize () {
    window.removeEventListener('resize', this.translateVonovoLogoAboutMe, true);
  },
  translateVonovoLogo () {
    if (window.isMobile && window.innerWidth >= 640) {
      window.location.reload();
    } else if (!window.isMobile && window.innerWidth < 640) {
      window.location.reload();
    }

    const logo = document.querySelector('.home__loading-logo');
    const documentWidth = window.innerWidth;
    const documentHeight = window.innerHeight;

    if (logo) {
      const logoWidth = 80;

      const leftMeasure = window.innerWidth >= 640 ? 0.8 : 1.23;
      const left = (documentWidth / 2) - (logoWidth / leftMeasure);
      const topMeasure = window.innerWidth >= 640 ? 0.8 : 1.85;
      const top = (documentHeight / 2) - (logoWidth / topMeasure);

      const scale = window.innerWidth >= 640 ? 0.8 : 0.3;

      logo.style.transform = window.innerWidth >= 640
        ? `translate(calc(-50% + ${left}px), calc(-50% + ${top}px)) scale(${scale})`
        : `translate(calc(-50% - ${left}px), calc(-50% - ${top}px)) scale(${scale})`;
    }
  },
  translateVonovoLogoAboutMe () {
    if (window.isMobile && window.innerWidth >= 640) {
      window.location.reload();
    } else if (!window.isMobile && window.innerWidth < 640) {
      window.location.reload();
    }

    const logo = document.querySelector('.home__loading-logo');
    const documentHeight = window.innerHeight;
    const documentWidth = window.innerWidth;

    if (logo) {
      const logoWidth = 51;
      const top = window.innerWidth >= 640 ? (documentHeight / 2) - (logoWidth / 0.8) : (documentHeight / 2) - (logoWidth / 1.85);
      const left = window.innerWidth >= 640 ? (documentWidth / 2) - (logoWidth / 0.7) : (documentWidth / 2) - (logoWidth / 1.85);

      const scale = window.innerWidth >= 640 ? 0.5 : 0.3;

      logo.style.transform = `translate(calc(-50% - ${left - 20}px), calc(-50% + ${top - 20}px)) scale(${scale})`;
    }
  }
};
