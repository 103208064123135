import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/circuit',
        name: 'circuit',
        component: () =>
          import(/* webpackChunkName: "Prefetch" */ '../views/CircuitView.vue')
      },
      {
        path: '/about',
        name: 'aboutMe',
        component: () =>
          import(/* webpackChunkName: "Prefetch" */ '../views/AboutMeView.vue')
      },
      {
        path: '/projects',
        name: 'projects',
        component: () =>
          import(/* webpackChunkName: "Prefetch" */ '../views/ProjectsView.vue'),
        children: [
          {
            path: ':name',
            name: 'project-details',
            component: () =>
              import(/* webpackChunkName: "Prefetch" */ '../views/ProjectDetailsView.vue')
          }
        ]
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes
});

export default router;
