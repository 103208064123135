import { createStore } from 'vuex';
import projects from '@/assets/data/projects.json';
import router from '@/router';

export default createStore({
  state () {
    return {
      circuitCreation: false,
      showProjects: false,
      showAbout: false,
      showProjectDetails: -1,
      openingProject: false
    };
  },
  getters: {
  },
  mutations: {
    RESET_PROJECT_DETAILS (state) {
      state.showProjectDetails = -1;
    },
    SET_SHOW_PROJECT_DETAILS (state, value) {
      if (state.openingProject) return;
      state.showProjectDetails = value;

      if (window.innerWidth >= 640) return;
      router.push({
        name: 'project-details',
        params: {
          name: projects[value].companyName
        }
      });
    },
    SET_OPENING_PROJECT (state, value) {
      state.openingProject = value;
    }
  },
  actions: {
  },
  modules: {
  }
});
