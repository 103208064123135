<template>
  <svg
     width="137mm"
     height="137.00003mm"
     viewBox="0 0 137 137.00003"
     version="1.1"
     id="vonovoMainLogo"
     sodipodi:docname="vonovoLogo2.svg"
     inkscape:version="1.2.1 (9c6d41e, 2022-07-14)"
     inkscape:export-filename="icon.png"
     inkscape:export-xdpi="5.9433613"
     inkscape:export-ydpi="5.9433613"
     xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
     xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:svg="http://www.w3.org/2000/svg">
    <defs
       id="defs2">
      <clipPath
         clipPathUnits="userSpaceOnUse"
         id="clipPath18789">
        <path
           style="display:inline;fill:#33a8e6;fill-opacity:1;stroke:none;stroke-width:2.16262;stroke-dasharray:none;stroke-opacity:1"
           d="m 93.536207,193.36398 c -0.511901,-0.074 -2.187229,-0.31003 -3.722934,-0.52432 C 74.21797,190.66347 58.509813,181.8526 47.934222,169.34925 37.319358,156.79947 31.762469,141.58269 31.742428,125.01032 31.701513,91.164817 56.231975,62.616943 89.999415,57.212412 c 5.417351,-0.867049 14.529315,-0.874844 20.227245,-0.01733 28.19293,4.243087 51.14044,25.821722 57.03978,53.637248 3.32702,15.68695 0.97989,32.31445 -6.54156,46.34115 -10.58861,19.74667 -29.36291,32.80732 -51.54394,35.85732 -3.68811,0.50713 -13.062972,0.70676 -15.644733,0.33314 z"
           id="path18791"
           inkscape:label="fill" />
      </clipPath>
    </defs>
    <g
       inkscape:label="containerFill"
       inkscape:groupmode="layer"
       id="layer1"
       style="display:inline;stroke-width:71.1;stroke-dasharray:none"
       transform="translate(-31.742381,-56.556996)"
       mask="none">
      <path
         style="display:inline;fill:#4aace0;fill-opacity:1;stroke:none;stroke-width:2.16262;stroke-dasharray:none;stroke-opacity:1"
         d="m 93.536211,193.36398 c -0.511901,-0.074 -2.187229,-0.31003 -3.722934,-0.52432 C 74.217974,190.66347 58.509817,181.8526 47.934226,169.34925 37.319362,156.79947 31.762473,141.58269 31.742432,125.01032 31.701517,91.164811 56.231979,62.616937 89.999419,57.212406 c 5.417351,-0.867049 14.529311,-0.874844 20.227241,-0.01733 28.19293,4.243087 51.14044,25.821722 57.03978,53.637254 3.32702,15.68695 0.97989,32.31445 -6.54156,46.34115 -10.58861,19.74667 -29.36291,32.80732 -51.54394,35.85732 -3.68811,0.50713 -13.062968,0.70676 -15.644729,0.33314 z"
         id="path3229"
         inkscapelabel="fill" />
      <path
         style="display:none;opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         id="path19066"
         sodipodi:type="arc"
         sodipodi:cx="100.0001"
         sodipodi:cy="125.00012"
         sodipodi:rx="41.856499"
         sodipodi:ry="41.856499"
         sodipodi:start="2.4713862"
         sodipodi:end="5.4244833"
         sodipodi:arc-type="arc"
         d="M 67.197436,150.99919 A 41.856499,41.856499 0 0 1 71.029409,94.789767 41.856499,41.856499 0 0 1 127.35,93.314959"
         sodipodi:open="true" />
    </g>
    <g
       inkscape:groupmode="layer"
       id="g18617"
       inkscape:label="board"
       transform="translate(-31.742381,-56.556996)"
       clip-path="url(#clipPath18789)">
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 105.79489,195.56675 V 170.4777 l 24.11046,-24.11046 V 89.297087"
         id="path19388" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="m 113.96209,192.72376 v -18.71575 l 24.85653,-24.85652 v -32.94888"
         id="path19392" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="m 122.71865,191.36715 v -13.8223 l 25.71734,-25.71734 v -47.69409"
         id="path19396" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 95.201014,63.401343 V 85.09251 l -31.191316,31.19133 v 25.70071 l 24.751948,24.75195 v 16.02941"
         id="path19402" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 86.119077,63.508812 V 80.435168 L 54.30834,112.24592 v 33.84931 l 24.403375,24.40338 v 15.4633"
         id="path19404"
         sodipodi:nodetypes="cccccc" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 106.32943,96.414448 V 76.732574 l 29.09066,-16.7955"
         id="path19406" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 115.70216,97.478295 V 82.155761 l 27.33828,-15.783765"
         id="path19408" />
      <path
         style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 44.249478,75.959608 V 172.47171"
         id="path19410" />
      <path
         style="display:inline;opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 157.36526,81.546423 V 129.25038"
         id="path19417" />

         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 105.79489,195.56675 V 170.4777 l 24.11046,-24.11046 V 89.297087"
            id="electronCurrent1" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="m 113.96209,192.72376 v -18.71575 l 24.85653,-24.85652 v -32.94888"
            id="electronCurrent2" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="m 122.71865,191.36715 v -13.8223 l 25.71734,-25.71734 v -47.69409"
            id="electronCurrent3" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 95.201014,63.401343 V 85.09251 l -31.191316,31.19133 v 25.70071 l 24.751948,24.75195 v 16.02941"
            id="electronCurrent4" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 86.119077,63.508812 V 80.435168 L 54.30834,112.24592 v 33.84931 l 24.403375,24.40338 v 15.4633"
            id="electronCurrent5"
            sodipodi:nodetypes="cccccc" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 106.32943,96.414448 V 76.732574 l 29.09066,-16.7955"
            id="electronCurrent6" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 115.70216,97.478295 V 82.155761 l 27.33828,-15.783765"
            id="electronCurrent7" />
         <path
            style="opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 44.249478,75.959608 V 172.47171"
            id="electronCurrent8" />
         <path
            style="display:inline;opacity:1;fill:none;fill-opacity:1;stroke:#fca8ff;stroke-width:2;stroke-opacity:1"
            d="M 157.36526,81.546423 V 129.25038"
            id="electronCurrent9" />

      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle18585-8"
         cx="157.36526"
         cy="131.25038"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle18585-9-3"
         cx="78.711716"
         cy="186.37437"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19413"
         cx="115.70216"
         cy="98.949127"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19415"
         cx="88.761642"
         cy="184.23671"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19420"
         cx="148.43599"
         cy="102.66259"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19422"
         cx="106.32943"
         cy="96.297783"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19424"
         cx="95.201012"
         cy="61.930511"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19426"
         cx="86.11908"
         cy="62.037979"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19428"
         cx="138.81862"
         cy="114.73177"
         r="2" />
      <circle
         style="display:inline;fill:#8aff91;fill-opacity:1;stroke:#919191;stroke-width:1;stroke-dasharray:none;stroke-opacity:1"
         id="circle19430"
         cx="129.90535"
         cy="87.826256"
         r="2" />
    </g>
    <g
       inkscape:label="container"
       inkscape:groupmode="layer"
       id="g4771"
       style="display:inline;stroke-width:71.1;stroke-dasharray:none"
       sodipodi:insensitive="true"
       transform="translate(-31.742381,-56.556996)">
      <path
         id="path16372"
         style="display:inline;fill:#ffbd5a;fill-opacity:1;stroke:none;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
         d="M 100.0001,102.52191 A 22.314798,22.478262 0 0 0 77.685181,125.00012 22.314798,22.478262 0 0 0 100.0001,147.47834 22.314798,22.478262 0 0 0 122.31501,125.00012 22.314798,22.478262 0 0 0 100.0001,102.52191 Z"
         sodipodi:insensitive="true"
         inkscape:label="iris" />
      <path
         style="opacity:0.19;fill:#000000;stroke-width:6.90896"
         id="path546"
         sodipodi:type="arc"
         sodipodi:cx="100.24238"
         sodipodi:cy="125.057"
         sodipodi:rx="68.5"
         sodipodi:ry="68.5"
         sodipodi:start="4.712389"
         sodipodi:end="1.5707963"
         sodipodi:arc-type="slice"
         d="m 100.24238,56.556999 a 68.5,68.5 0 0 1 68.5,68.500001 68.5,68.5 0 0 1 -68.5,68.5 v -68.5 z"
         inkscape:label="shadow" />
      <g
         id="g271"
         style="display:inline;stroke-width:2;stroke-dasharray:none"
         inkscape:label="movingEye">
        <ellipse
           style="fill:#060606;fill-opacity:1;stroke:#020000;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
           id="path20246"
           cx="96.555725"
           cy="120.44852"
           rx="6.8277259"
           ry="6.8277273"
           inkscape:label="pupil" />
        <circle
           style="display:inline;fill:#fdfdfd;fill-opacity:1;stroke:none;stroke-width:2;stroke-dasharray:none;stroke-opacity:1"
           id="path23474"
           cx="93.00338"
           cy="115.45064"
           r="4"
           inkscape:label="light" />
      </g>
    </g>
  </svg>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'VonovoLogo',
  data () {
    return {
    };
  },
  mounted () {
    this.animateAllCurrents();
    this.animateEyes();
  },
  beforeLeave () {
    this.eyeTimeline.kill();
    this.currentAnimation.kill();
  },
  methods: {
    animateEyes () {
      this.eyeTimeline = gsap.timeline({
        repeat: -1,
        repeatDelay: 2
      });

      this.eyeTimeline
        .add(this.eyeBallSide(), '0')
        .add(this.eyeBallReflection(), '0');
    },
    eyeBallReflection () {
      const eyeBallReflectionTl1 = gsap.timeline({ yoyo: true, repeat: -1, repeatDelay: 0.4 });
      eyeBallReflectionTl1.to('#path23474', { duration: 1, x: 0.5, ease: 'sine.out' })
        .to('#path23474', { duration: 1, rotation: 40, x: -0.5, ease: 'sine.out' });

      return eyeBallReflectionTl1;
    },
    eyeBallSide () {
      const eyeBallSideTl1 = gsap.timeline({ repeat: -1, repeatDelay: 2 });
      eyeBallSideTl1.to('#g271', { duration: 0.6, x: 6, ease: 'expo.inOut' })
        .to('#path20246', { duration: 0.6, scale: 1.15, ease: 'expo.inOut' }, '<')
        .to('#g271', { duration: 0.75, delay: 0.3, x: -4, ease: 'expo.inOut' })
        .to('#g271', { duration: 2, delay: 0.8, x: 8, ease: 'expo.inOut' })
        .to('#path20246', { duration: 2, scale: 0.8, ease: 'expo.inOut' }, '<')
        .to('#g271', { duration: 1.5, delay: 0.5, x: -2, ease: 'expo.inOut' })
        .to('#path20246', { duration: 1.5, scale: 1, ease: 'expo.inOut' }, '<')
        .to('#g271', { duration: 0.385, delay: 0.2, x: 0, ease: 'sine.inOut' });

      return eyeBallSideTl1;
    },
    animateAllCurrents () {
      this.currentAnimation = gsap.to('path[id*="electronCurrent"]', {
        duration: '1',
        ease: 'linear',
        strokeDashoffset: '+=60',
        repeat: '-1',
        stagger: {
          onStart: function () {
            this.targets()[0].style.stroke = '#84ff7a';
          }
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/scss/index';

svg {
   cursor: pointer;
}
path[id*='electronCurrent'] {
  stroke-dasharray: 10 50;
  stroke-dashoffset: 10;
}
[inkscapelabel='fill'] {
   animation: colorChange 20s infinite;
}

@keyframes colorChange {
   0% {
      fill: $blue;
   }
   25% {
      fill: #444444;
   }
   50% {
      fill: white;
   }
   75% {
      fill: #555;
   }
   100% {
      fill: $blue;
   }
}
</style>
