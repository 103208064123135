<template>
  <div class="loading__container">
    <div class="loading__bar">
      <div class="loading__fill" :style="`clip-path: inset(0% ${100 - percentage}% 0% 0%)`" />
    </div>
    <div class="loading__percentage">
      {{ `${percentage}%` }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoadingBar',
  props: {
    percentage: Number
  },
  data () {
    return {
    };
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.loading {
  &__bar {
    height: 16px;
    border: 1px solid white;
    width: 120px;
    border-radius: 4px;
  }
  &__fill {
    height: 100%;
    background-color: white;
    width: 100%;
    transition: all .5s linear;
    clip-path: inset(0 100% 0 0);
  }
  &__container {}
  &__percentage {
    padding-top: 8px;
    line-height: 15px;
    font-size: 15px;
  }
}
</style>
