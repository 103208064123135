<template>
  <main class="home">
    <router-view>
    </router-view>
    <VonovoLogo
      class="home__loading-logo"
      :class="{ 'home__loading-logo--loaded': !isLoading }"
      @click="handleLogoClick"
    />
    <Transition duration="800" name="disappear">
      <div
        v-if="isLoading"
        class="home__loading-bar-container"
      >
        <LoadingBar
          class="home__loading-bar"
          :percentage="percentage"
        />
      </div>
    </Transition>

  </main>
</template>

<script>
// @ is an alias to /src
import VonovoLogo from '@/components/VonovoLogo.vue';
import LoadingBar from '@/components/Loading/LoadingBar.vue';

export default {
  name: 'HomeView',
  components: {
    VonovoLogo,
    LoadingBar
  },
  data () {
    return {
      percentage: 0,
      isLoading: true,
      chipTimeout: null,
      loadingTimeout: null,
      finishedLoading: true
    };
  },
  unmounted () {
    this.removeLoadingTimer();
  },
  created () {
    this.setLoadingTimer();
  },
  methods: {
    handleLogoClick () {
      if (this.$route.name == 'circuit') {
        const chatContainer = document.querySelector('.chat-container');
        if (!Array.from(chatContainer.classList).includes('appear')) {
          chatContainer.classList.add('appear');
        } else {
          this.emitter.emit('show-dialog');
        }
      } else {
        this.emitter.emit('go-to-home');
      }
    },
    increasePercentage () {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }

      const randomInterval = Math.floor(Math.random() * (200 - 100 + 1) + 100);
      const randomPercentageIncrease = Math.floor(Math.random() * (17 - 8 + 1) + 8);

      this.loadingTimeout = setTimeout(() => {
        if (this.percentage == 100) return;

        if (this.percentage + randomPercentageIncrease > 100) {
          this.percentage = 99;
        } else {
          this.percentage += randomPercentageIncrease;
          this.increasePercentage();
        }
      }, randomInterval);
    },
    handleLoadingFinished () {
      if (document.readyState == 'complete') {
        this.percentage = 100;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push({
            name: 'circuit'
          });
        }, 100);
      }
    },
    removeLoadingTimer () {
      window.document.removeEventListener('readystatechange', this.handleLoadingFinished, false);
    },
    setLoadingTimer () {
      window.document.addEventListener('readystatechange', this.handleLoadingFinished, false);
      this.increasePercentage();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/scss/index';

.home {
  width: 100%;
  height: 100%;
  background-color: $dark-grey;
  overflow: hidden;
  position: relative;

  &__loading-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 170px;
    height: 170px;
    z-index: 2;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease-in-out;

    &--loaded {
      transform: translate(-50%, -50%) scale(0.47);
      @include media-breakpoint('sm') {
        transform: translate(-50%, -50%) scale(0.65);
      }
    }
  }

  &__loading-bar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__loading-bar {
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, calc(-50% + 130px));
  }

}

.disappear-enter-active .home__loading-bar {
  transition: all 0.4s ease-out;
}

.disappear-leave-active .home__loading-bar {
  transition: all 0.4s ease-in-out;
}

.disappear-enter-from .home__loading-bar,
.disappear-leave-to .home__loading-bar {
  opacity: 0;
}
</style>
